
/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
[contenteditable]:not([contenteditable=false]):focus-visible,
[tabindex]:focus-visible,
a:focus-visible,
audio:focus-visible[controls],
button:focus-visible,
details:focus-visible,
details>summary:first-of-type:focus-visible,
input[type=button]:focus-visible,
input[type=checkbox]:focus-visible+label:before,
input[type=image]:focus-visible,
input[type=radio]:focus-visible+label:before,
input[type=reset]:focus-visible,
input[type=submit]:focus-visible,
video:focus-visible[controls],
.mat-nav-list .mat-list-item:focus-visible,
input[readonly="true"]:focus-visible,
[role="option"]:focus-visible,
.mat-chip-list-wrapper .mat-mdc-standard-chip:focus-visible {
    outline-style: solid;
    outline-color: #0a76f6;
    outline-offset: 2px;
    outline-width: 2px;
    z-index: 2;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.filters .mat-button-toggle-standalone,
.filters .mat-button-toggle-group {
    overflow: initial;
}

[role="combobox"][tabindex]:focus-visible {
    outline-width: 0;
}
