.snackbar {
    border-radius: 5px;
    max-width: 80vw;
    font-size: 90%;
}

.success-snackbar {
    background: #57b347;
    color: white;
}
.success-snackbar button {
    color: white !important;
    border: none;
    font-weight: bold;
}

.info-snackbar {
    background: #263238;
    color: white;
}
.info-snackbar button {
    color: white !important;
    border: none;
    font-weight: bold;
}

.warning-snackbar {
    background: #ffc400;
    color: white;
}
.warning-snackbar button {
    color: white !important;
    border: none;
    font-weight: bold;
}

.error-snackbar {
    background: #F31109;
    color: white;
}
.error-snackbar button {
    color: white !important;
    border: none;
    font-weight: bold;
}
