.mat {
    &-sidenav-container {
        &.mat-drawer-container {
            overflow: initial;
        }

        .mat-drawer-content {
            overflow: initial;
        }
    }
}

// Focus on switch/toggle
.cdk-keyboard-focused {
    label {
        /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        &.mat-slide-toggle-label,
        &.mat-radio-label {
            outline-style: solid;
            outline-color: #0a76f6;
            outline-offset: 7px;
            outline-width: 2px;
            z-index: 2;
            border-radius: 50px;
        }
    }
}
