
@import 'toastr/toastr';
@import '@lukana/ng-pick-datetime/assets/style/picker.min.css';
@import 'bootstrap/scss/bootstrap-grid';
@import 'theme/fonts';
@import 'theme/lineo-vars';
@import 'theme/demand';
@import 'theme/typography';
@import 'theme/layout.scss';
@import 'theme/spacing.scss';
@import 'theme/breakpoints.scss';
@import 'theme/focus';
@import 'theme/mat';
@import 'theme/toast.scss';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'TheSans OT5 Plain', Roboto, sans-serif;
  background-color: #f5f5f5;
}

.mat-mdc-dialog-container {

  .mat-mdc-fab.mat-primary,
  .mat-mdc-unelevated-button.mat-primary,
  .mat-mdc-mini-fab.mat-primary,

  .mat-mdc-raised-button:not([class*='mat-elevation-z']) {
    box-shadow: none !important;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  .mat-button-focus-overlay {
    display: none !important;
  }
}

.observed-impact {
  /*TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
  .mat-option-text {
    flex-grow: inherit !important;
    margin-right: 0.5em;
  }
}

.header {
  color: rgba(0, 0, 0, 0.87);
  font-family: 'TheSans OT8';
  font-size: 24px;
  line-height: 33px;
}

.yellow-btn {
  color: #E09F25;
  border-color: none;
}

.wrapper {
  margin-left: 50px;
  margin-right: 50px;
}

@media (max-width: $mobile) {
  .wrapper {
    margin: auto;
    margin-right: 20px;
  }

  .mat-mdc-dialog-container {
    width: 100vw;
    height: 120vh;
  }
}

.red-bubble {
  display: inline-block;
  /*background: #e95f47;*/
  background: #CC4C36;
  height: 24px;
  min-width: 24px;
  border-radius: 32px;
  text-align: center;
  line-height: 24px;
  color: white;
  font-size: 14px;
  font-weight: 100;
  font-family: 'TheSans OT5 Plain';
  box-sizing: border-box;
  vertical-align: middle;
  margin-bottom: 7px;
  padding: 0 4px;
}



.infos-tooltip-title {
  .headline {
    margin-right: 25px;
  }
  .tooltip-icon {
    float: right;
    position: relative;
  }
}

.uppercased {
  text-transform: uppercase !important;
}

.capitalized {
  text-transform: capitalize;
}

.round {
  border-radius: 50%;
  border: 1px solid;
}

.clickable {
  cursor: pointer;
}

.mixed-round-border {
  border-top-right-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
}

.bold {
  font-weight: 800;
}

.break-words {
  word-break: break-all;
}

.d-print-only {
  @extend .d-none;
  @extend .d-print-block;
}

.ml-25 {
  margin-left: 25px;
}


.row-no-margin {
  @extend .row;

  margin: 0;

  .col-sm-12,
  .col-sm-9,
  .col-lg-9 {
    padding-left: 0;
    padding-right: 0;
  }
}

.bold {
  font-weight: 800;
}



.simple-notification.info {
  background: #55555a !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-mdc-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $peacock-blue !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-radio-label-content {
  font-family: 'TheSans', sans-serif;
  font-size: 13px;
  margin-left: 11px;
}

/*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-mdc-radio-button.mat-accent.mat-radio-checked .mat-radio-inner-circle {
  background: $peacock-blue !important;
}

.mat-mdc-raised-button:hover{
  &:not([disabled='true']):not([class*="mat-warn"]) {
    background-color: $peacock-blue-light !important;
  }

}

.__position-bottom-right {
  bottom: 86px;
  right: 86px;
  @include sm {
    bottom: 5px;
    right: 5px;
    left: 5px;
  }
}

.__hv360-toastr {
  background: $black;
  color: $white;
  padding: 13px 16px !important;
  @include sm {
    width: 100%;
  }
  .__title {
    font-family: 'TheSans', sans-serif;
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 10px;
    line-height: 1.4;
    letter-spacing: normal;
    color: rgba(255, 255, 255, 0.87);
  }

  .__message {
    font-family: 'TheSans', sans-serif;
    font-size: 16px;
    line-height: normal;
    color: rgba(255, 255, 255, 0.87);
  }
}

#toast-container > div {
  box-shadow: none;
  border-radius: 4px;
  width: 480px;
  min-height: 86px;
  opacity: 1;
  &:hover {
    box-shadow: none;
  }
  @include sm {
    width: 100%;
    margin: 0;
  }
}

/*TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
mat-progress-bar .mat-progress-bar-buffer {
  background: white;
}

.link {
  color:  #007bff !important;
}

.no-child-padding > [class*='col-'] {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.full-width {
  width: 100%;

  & > * {
    width: 100%;
  }
}

.hv360-loading-layer {
  position: absolute;
  top: -0.2px;
  right: -0.2px;
  bottom: -0.2px;
  left: -0.2px;
  border-radius: 2px;
  background: $peacock-blue;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: not-allowed;
  .mat-mdc-progress-spinner circle,
  .mat-mdc-progress-spinner circle {
    stroke: white;
  }
}

.table-footer-mobile {
  @include sm {
    flex-direction: row !important;
    .mat-mdc-paginator-range-label {
      display: none !important;
    }
  }
}
.main-view {
  padding: 16px;
  .main-actions {
    padding-top: 57px;
    @include sm {
      padding-top: 0;
    }
  }
}
.full-width-btn-mobile {
  @include sm {
    width: 100%;
    margin-bottom: 15px;
    max-width: 300px;
    margin: auto;
  }
}

.footer-accordion {
  max-height: 100vh;
  .mat-expansion-panel-content {
    max-height: calc(100vh - 64px);
    overflow: auto;
  }
}

.cdk-overlay-pane {
  max-width: 100vw !important;
}

.panelClassMobile {
  position: unset !important;
}

@media (max-width: $mobile) {
  .cdk-overlay-pane {
    margin-left: unset !important;
  }
}

.panel {
  position: static !important;
  margin-top: 50px !important;
}
.panel-mobile {
  height: 90vh !important;
}


@media print {
  body {
    zoom: 0.65 !important;
  }
  @page {
    margin: 1cm 0 1cm 0;
  }

  .msg-bubble {
    max-width: unset !important;
    width: 600px !important;
  }

  .__hv360-toastr {
    display: none !important;
  }

  .print-break-before {
    page-break-before: always;
  }

  blockquote {
    border: none;
  }

  .last-message {
    margin-top: 25px !important;
  }

  .print-no-margin {
    margin-left: 0px !important;
  }
}

.ng-select-custom {
  .ng-option-selected {
      background: white !important;
  }
  .ng-option-marked {
    background: #e0e0e0 !important;
  }
}

.rgpd-block {
  display: flex;
  margin-left: 12px !important;
}

@media (max-width: 1348px) {
  .rgpd-block {
    margin-left: 15px;
  }
}

.rgpd-info {
  color: black;
  font-size: 11px;
  margin-left: 4px !important;
}

.rgpd-info-first {
  margin-top: 5px;
}

.rgpd-icon {
  font-size: 21px;
}

.rgpd-icon-spacing {
  margin-left: 10px;
  margin-right: 5px;
}

.mat-mdc-standard-chip {
  min-height: unset !important;
  height: unset !important;
  border-radius: 24px !important;
}

.text-justify {
  text-align: justify;
}

.mat-mdc-tooltip {
  font-size: 12px !important;
}

.mat-mdc-form-field-required-marker {
  margin-left: 3px;
  color: red;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: unset !important;
}
.mat-mdc-form-field-focus-overlay {
  background-color: unset !important;
}

.mat-mdc-form-field:not(.mat-form-field-disabled) .mat-mdc-floating-label.mdc-floating-label {
  font-family: 'TheSans OT5 Plain', Roboto, sans-serif !important;
}

.mat-icon {
  font-size: 24px !important; 
  height: 24px !important; 
  width: 24px !important; 
  line-height: 1 !important; 
  display: inline-flex !important; 
  justify-content: center !important;
  align-items: center !important;
  transform: none !important; 
}

.mat-button-toggle-group .mat-button-toggle-appearance-standard.mat-button-toggle-checked {
  background-color: $peacock-blue !important;
}

.mat-button-toggle {
  font-size: 14px !important; 
  font-family: 'TheSans OT5 Plain', Roboto, sans-serif !important;
}

.ng-dropdown-panel .ng-dropdown-header {
  padding: 0 16px 0 7px !important;
}

.mdc-evolution-chip__text-label {
  display: flex; /* Aligne les enfants horizontalement */
  align-items: center; /* Centre verticalement */
  white-space: nowrap; /* Empêche le retour à la ligne */
  overflow: hidden; /* Cache le contenu débordant */
  text-overflow: ellipsis; /* Ajoute "..." si le texte déborde */
}

.chip-name,
.chip-value {
  display: inline; /* Transforme les div en éléments en ligne */
}

.mdc-button {
  width: auto !important;
  height: auto !important;
  min-height: 36px !important;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-value-container {
  padding-top:4px;
}