@import "lineo-vars";

@font-face {
  font-family: 'FSSinclair';
  src: url('/assets/fonts/FSSinclair/FSSinclair-Italic.eot');
  src: url('/assets/fonts/FSSinclair/FSSinclair-Italic.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/FSSinclair/FSSinclair-Italic.woff2') format('woff2'),
  url('/assets/fonts/FSSinclair/FSSinclair-Italic.woff') format('woff'),
  url('/assets/fonts/FSSinclair/FSSinclair-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'FSSinclair-regular';
  src: url('/assets/fonts/FSSinclair-regular/FSSinclair.woff2') format('woff2'),
  url('/assets/fonts/FSSinclair-regular/FSSinclair.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TheSans';
  src: url('/assets/fonts/TheSans/thesans-regular_44265-webfont.woff2') format('woff2'),
  url('/assets/fonts/TheSans/thesans-regular_44265-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TheSans-OT8';
  src: url('/assets/fonts/TheSans-OT8/TheSans-OT8.woff2') format('woff2'),
  url('/assets/fonts/TheSans-OT8/TheSans-OT8.woff') format('woff'),
  url('/assets/fonts/TheSans-OT8/TheSans-OT8.ttf') format('truetype');
}

.title-h3 {
  font-size: 20px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--black);
}

.paragraph {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: var(--black);
}

.lpad {
  margin-left: 13px;
}

.progress-fill { word-wrap: break-word; }

.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100% - 110px);
  height: 1.2em;
  white-space: nowrap;
}
