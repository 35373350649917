
$screen-lg-min: 991px;
@mixin sm {
  @media (max-width: #{$screen-lg-min}) {
    @content;
  }
}
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

.hide-mobile {
  @include sm {
    display: none !important;
  }
}
.hide-desktop {
  @include lg {
    display: none !important;
  }
}
