@use '@angular/material' as mat;
@include mat.core();
/*$primary-color: #54BBBA;*/
$header-color: #002d62;
$primary-color: #002d62;
$primary-color-hover:#8DACCD;
$mat-custom-palette: (
  50: $primary-color,
  100: $primary-color,
  200: $primary-color,
  300: $primary-color,
  400: $primary-color,
  500: $primary-color,
  600: $primary-color,
  700: $primary-color,
  800: $primary-color,
  900: $primary-color,
  A100: $primary-color,
  A200: $primary-color,
  A400: $primary-color,
  A700: $primary-color,
  contrast: (
    50: $primary-color,
    100: $primary-color,
    200: $primary-color,
    300: $primary-color,
    400: $primary-color,
    500: white,
    600: white,
    700: white,
    800: $primary-color,
    900: $primary-color,
    A100: $primary-color,
    A200: white,
    A400: white,
    A700: white,
  )
);
$candy-app-primary: mat.define-palette($mat-custom-palette);
$candy-app-accent:  mat.define-palette($mat-custom-palette);
$candy-app-theme: mat.define-light-theme($candy-app-primary, $candy-app-accent);
@include mat.all-component-themes($candy-app-theme);

.owl-dt-control-button-content{
    color:$primary-color;
}
.owl-dt-container-info .owl-dt-container-info-active {
    color: $primary-color;
}
.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
    color: rgba(255, 255, 255, .85);
    background-color: $primary-color;
}

.ng-select.ng-select-focused .ng-select-container .ng-value-container .ng-placeholder{
    color: $primary-color;
}
.ng-select.ng-select-focused .ng-select-container .ng-arrow-wrapper .ng-arrow{
    color: $primary-color;
}
.ng-select.ng-select-focused .ng-select-container:after {
    border-color: $primary-color
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected{
    color: $primary-color
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value{
    background-color: #FFF;
    color: #000;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
    font-size: 16px;
    font-weight: 400;
    opacity: 0.8;
}