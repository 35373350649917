@import 'client-theme';


$black: #000000;
$peacock-blue-header: $header-color;
$peacock-blue: $primary-color;
$peacock-blue-light: $primary-color-hover;
$cherry: #cd0b24;
$slate-grey: #555555;
$bg: #f5f5f5;
$squash: #e09f25;
$white: #fff;
$pastel-red: #e95f47;
$snot: #97bf0d;
$border-grey: #cccccc;
$text-grey: #757575;
$status-grey: #ecf0f1;

$dmd-status-color: (
  demandeur: (
    a_envoyer: $white,
    envoyee: $status-grey,
    en_cours: $snot,
    affectee: $snot,
    a_cloturer: $snot,
    en_attente_cloture: $snot,
    a_completer: $squash,
    en_attente_abandon: $squash,
    rejetee: $pastel-red,
    suspendue: $pastel-red,
    abandonnee: $text-grey,
    cloturee: $text-grey,
  ),
  exploitant: (
    a_envoyer: $white,
    envoyee: $squash,
    en_cours: $snot,
    affectee: $snot,
    a_cloturer: $snot,
    en_attente_cloture: $snot,
    a_completer: $squash,
    en_attente_abandon: $squash,
    rejetee: $pastel-red,
    suspendue: $pastel-red,
    abandonnee: $text-grey,
    cloturee: $text-grey,
  )
);
$mobile: 991px;
$tablet: 1003px;
$default-radius: 3px;
$default-padding: 24px;
.bold {
  font-weight: 800;
}
