@import "lineo-vars";
@import "src/theme/breakpoints";

.btn-wrapper {
  button {
    @extend .demand-action-btn;
  }

  .mat-icon {
    overflow: visible !important;
  }
  @include lg {
    padding-right: 12px;
  }
  @include sm {
    padding-right: 0px;
    width: 100%;
  }
  display: inline-block;
}

.pdf-print-button {
  @include sm {
    display: none !important;
  }
}

.demand-action-btn {
  min-width: 285px !important;
  max-width: 100% !important;

  @include lg {
    margin-bottom: 15px;
  }
  @include sm {
    min-width: 100% !important;
    max-width: 100% !important;
    width: 100% !important;
    margin-bottom: 5px;
  }
}