@import 'lineo-vars';
@import 'mixins';
@import 'buttons';

.dmd-status {
  width: 100vw;
  min-height: 72px;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.24);
  background-color: #dddddd;
  padding: 15.5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: fixed;
  left: 0;
  bottom: 0;

  &-wrap {
    display: flex;
  }

  &-text-wrap {
    margin: 0 10px;
  }

  &-circle {
    @include height-and-width(35px);
    margin-top: 3px;
  }

  &-updated-date {
    font-size: 13px;
    color: #5F5F63;
  }

  &-btn-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    &--demandeur {
      margin-right: -15px;
    }
  }
}

.full-width-btn  {
  width: 100%;
  * {
    width: 100%;
  }
  .btn-wrapper {
    margin-top: 10px;
  }
}

.btn-transition-outlined {
  min-width: 285px;
}

.urgent-demand {
  color: red;
}
